<div *ngIf="loading" class="container text-center">
  <div class="spinner-wrapper py-4">
    <div class="spinner-border text-primary" style="width:4rem; height:4rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>

<div *ngIf="!loading && accessForbidden && dictionary">
  <h5 class="text-danger text-center mt-4">
    {{dictionary['Přístup'][country]}}
  </h5>
</div>

<div *ngIf="!loading && !accessForbidden && dictionary" class="container border rounded mt-4 px-4 py-2" 
style="background-color: #f4f4f4; border-color: #444444 !important">
  <div class="row border-bottom border-2 rounded-top py-3 shadow" 
  style="background-color: #ffffff !important;" [style.border-color]="color2 + ' !important'">
    <div *ngIf="logo" class="col-md-8">
      <img [src]="logo.content | safeResource" height="100px" alt="logo"/>
    </div>
    <div class="col-md-4">
      <div *ngIf="company">
        <div>{{company.company}}</div>
        <div>{{company.street}}, {{company.zip}} {{company.city}} ({{company.country}})</div>
        <div>{{dictionary['DIČ'][country]}}: {{company.tin}}</div>
      </div>
    </div>
  </div>

  <!-- 1. Objednavka -->
  <div class="row py-3 mt-2">
    <h5 class="col-md-12 bg-secondary text-white rounded py-1 mb-2">
      {{dictionary['1Objednávka'][country]}}
      <span *ngIf="order.valid_token_date">{{order.valid_token_date | date: 'dd.MM.yyyy'}}</span>
    </h5>

    <div class="col-md-9">
      <h5>
        {{dictionary['Objednávka'][country]}} - {{dictionary['Smlouva'][country]}}
        {{order.orderNumberFormatted}} 
        {{dictionary['ZeDne'][country]}} {{order.created_time_date | date: 'dd.MM.yyyy HH:mm'}}
      </h5>

      <div class="mt-2 fw-bold">{{dictionary['Dopravce'][country]}}:</div>
      <div *ngIf="order.order_comp_book" class="my-2">
        {{order.order_comp_book.company}}, 
        {{order.order_comp_book.street}}, {{order.order_comp_book.zip}}, 
        {{order.order_comp_book.city}} ({{order.order_comp_book.country}}),
        {{dictionary['DIČ'][country]}}: {{order.order_comp_book.tin}}
      </div>
      <div class="my-2">
        <div *ngIf="order.itinerary?.length">
          <div *ngFor="let it of order.itinerary">
            <div class="row mb-1">
              <div class="col-md-12">
                <div *ngIf="it.type == 'L' || it.type == 'U' || it.type == 'W' || it.type == 'V' || it.type == 'T'">
                  <img *ngIf="it.type == 'L'" class="me-2" src="../../../assets/img/loading.svg" 
                  alt="icon" height="18px"/>
                  <img *ngIf="it.type == 'U'" class="me-2" src="../../../assets/img/unloading.svg" 
                  alt="icon" height="18px"/>
                  
                  <span *ngIf="order.delivery_type && (it.type=='W' || it.type=='V' || it.type=='T')">
                    <img *ngIf="order.delivery_type == 'D'" 
                    class="me-2" src="../../../assets/img/loading.svg" alt="icon" height="18px"/>
                    <img *ngIf="order.delivery_type == 'P'" 
                    class="me-2" src="../../../assets/img/unloading.svg" alt="icon" height="18px"/>
                  </span>
                  {{it.arrival_time_date | date: 'dd.MM.yyyy HH:mm'}} - 
                  <span *ngIf="it.place_name && it.place_street && it.place_city && it.place_country; else fullAddress">
                    {{it.place_name}}, {{it.place_street}}, {{it.place_zip}} 
                    <b>{{it.place_city}}</b>,
                    {{it.place_country}} 
                  </span>
                  <ng-template #fullAddress>
                    {{it.address}}
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        {{dictionary['SmluvníCena'][country]}}: <b>{{order.contracted_price | price}} {{order.currency}}</b>
      </div>
    </div>
    <div class="col-md-3 mt-2 text-center">
      <a *ngIf="orderPdf" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#pdfModal">
        <img src="../../../assets/img/order.png" class="shadow" height="150px" alt="pdf"
        ngbTooltip="Náhled objednávky" container="body"/>
      </a>
    </div>
    <div class="col-md-6">
      <div class="mt-3">
        <button class="btn btn-warning w-100" type="button" [disabled]="!validTokenOrder"
        (click)="openImportModal()">
          {{dictionary['ImportovatTA'][country]}} *
        </button>
      </div>
    </div>
    <div *ngIf="country == 'CZ' || country == 'SK'" class="col-md-6">
      <div class="mt-3">
        <a class="btn btn-outline-secondary w-100" href="https://www.truckagenda.eu/" target="_blank">
          * Více informací o dopravním SW TruckAgenda
          <!-- Vyzkoušet zdarma dopravní SW TruckAgenda -->
        </a>
      </div>
    </div>
  </div>

  <div class="row border-top pt-3 pb-1">
    <div class="col-12">
      <ul class="nav nav-tabs nav-tabs-responsible" id="nav-tabs-ul" role="tablist">
        <li class="nav-item text-center nav-item-width">
          <a class="nav-link px-1 fw-bold text-truncate active" id="nav-agreement-tab"
          [class.active]="!input_activate_nav || input_activate_nav == '2'"
          data-bs-toggle="tab" data-bs-target="#nav-agreement" 
          type="button" role="tab" aria-controls="nav-agreement" aria-selected="true">{{dictionary['2Souhlas'][country]}}</a>
        </li>
        <li class="nav-item text-center nav-item-width">
          <a class="nav-link px-1 fw-bold text-truncate" id="nav-messages-tab" 
          [class.active]="input_activate_nav == '3'"
          data-bs-toggle="tab" data-bs-target="#nav-messages" 
          type="button" role="tab" aria-controls="nav-messages" aria-selected="true">{{dictionary['3Dispozice'][country]}}</a>
        </li>
        <li class="nav-item text-center nav-item-width">
          <a class="nav-link px-1 fw-bold text-truncate" id="nav-itinerary-tab" 
          [class.active]="input_activate_nav == '4'"
          data-bs-toggle="tab" data-bs-target="#nav-itinerary" 
          type="button" role="tab" aria-controls="nav-itinerary" aria-selected="true">{{dictionary['4Dokončení'][country]}}</a>
        </li>
        <li class="nav-item text-center nav-item-width">
          <a class="nav-link px-1 fw-bold text-truncate" id="nav-invoice-tab" 
          [class.active]="input_activate_nav == '5'"
          data-bs-toggle="tab" data-bs-target="#nav-invoice" 
          type="button" role="tab" aria-controls="nav-invoice" aria-selected="true">{{dictionary['5Fakturace'][country]}}</a>
        </li>
      </ul>
      <div class="tab-content" id="nav-tabContent">
        <!-- 2. Souhlas s objednávkou -->
        <div class="tab-pane fade show active" id="nav-agreement"  
        [class.show]="!input_activate_nav || input_activate_nav == '2'"
        [class.active]="!input_activate_nav || input_activate_nav == '2'" 
        role="tabpanel" aria-labelledby="nav-agreement-tab" tabindex="0">
          <!-- [class.low-opacity]="order.accepted_by" -->
          <form class="row py-3 mt-2" [noValidate]="false">
            <h5 class="col-md-12 bg-secondary text-white rounded py-1 mb-2">
              {{dictionary['2Souhlas'][country]}}
            </h5>
            
            <div class="col-md-12">
              <h5>{{dictionary['DoplníDopravce'][country]}}</h5>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-1">
                <label class="form-label mb-0">{{dictionary['SPZvozidla'][country]}} *</label>
                <input class="form-control" type="text" [placeholder]="dictionary['Doplnte'][country]"  
                [(ngModel)]="spz" (ngModelChange)="attributesChanged = true" 
                [ngModelOptions]="{standalone: true}"/>
                <!-- <input class="form-control {{spz ? 'is-valid' : 'is-invalid'}}" 
                [class.border-danger]="submittedConfirmation && !spz"
                type="text" [placeholder]="dictionary['Doplnte'][country]"  
                [(ngModel)]="spz" (ngModelChange)="attributesChanged = true" 
                [ngModelOptions]="{standalone: true}" required/>
                <div *ngIf="!spz" class="fst-italic text-danger">
                  * {{dictionary['PovinnéPole'][country]}}
                </div> -->
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-1">
                <label class="mb-0">{{dictionary['SPZnávěsu'][country]}}</label>
                <input class="form-control" type="text" [placeholder]="dictionary['Doplnte'][country]" 
                [(ngModel)]="spz_trailer" (ngModelChange)="attributesChanged = true"
                [ngModelOptions]="{standalone: true}"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-1">
                <label class="mb-0">{{dictionary['JménoŘidiče'][country]}}</label>
                <input class="form-control" type="text" [placeholder]="dictionary['Doplnte'][country]" 
                [(ngModel)]="driver_name" (ngModelChange)="attributesChanged = true"
                [ngModelOptions]="{standalone: true}"/>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-1">
                <label class="mb-0">{{dictionary['Tel.Řidiče'][country]}}</label>
                <input class="form-control" type="text"
                [class.border-danger]="!driver_phone_valid" placeholder="formát +420123456789"
                [(ngModel)]="driver_phone" (ngModelChange)="attributesChanged = true"
                [ngModelOptions]="{standalone: true}"/>
                <div *ngIf="!driver_phone_valid" class="fst-italic text-danger">
                  * Formát +420123456789
                </div>
              </div>
            </div>
        
            <div class="col-md-3">
              <div class="form-group mb-1">
                <label class="mb-0">{{dictionary['Potvrdil'][country]}} *</label>
                <input class="form-control {{accepted_by ? 'is-valid' : 'is-invalid'}}" 
                [class.border-danger]="submittedConfirmation && !accepted_by"
                type="text" [placeholder]="dictionary['VaseJmeno'][country]"
                [disabled]="order.accepted_time != null" 
                [(ngModel)]="accepted_by" [ngModelOptions]="{standalone: true}" required/>
                <div *ngIf="!accepted_by" class="fst-italic text-danger">
                  * {{dictionary['PovinnéPole'][country]}}
                </div>
              </div>
            </div>
            <div class="col-md-9 mt-1">
              <div class="form-group mb-1">
                <label class="mb-0">
                  {{dictionary['Výhrady'][country]}}
                </label>
                <textarea class="form-control" rows="1" [disabled]="order.accepted_time != null"
                [(ngModel)]="additional_note" [ngModelOptions]="{standalone: true}"></textarea>
              </div>
            </div>
        
            <div class="col-md-12 mt-3">
              <button *ngIf="order.accepted_time" class="btn btn-primary w-100 mb-2" type="button"
              [disabled]="!attributesChanged || !driver_phone_valid"
              data-bs-toggle="modal" data-bs-target="#confirmationEditModal">
              <!-- !spz ||  -->
                {{dictionary['UpravitUdaje'][country]}}
              </button>
              <button class="btn btn-success w-100"
              [disabled]="!validTokenOrder || order.accepted_time != null || !driver_phone_valid"
              type="submit" (click)="openConfirmationModal()">
              <!-- data-bs-toggle="modal" data-bs-target="#confirmationModal" -->
                {{dictionary['PotvrditObjednávku'][country]}}
              </button>
              <div class="fst-italic mt-1">
                * {{dictionary['Souhlas'][country]}}
              </div>
            </div>
          </form>
        </div>
        <!-- 3. Dispozice pro řidiče -->
        <div class="tab-pane fade" id="nav-messages" [class.show]="input_activate_nav == '3'" [class.active]="input_activate_nav == '3'" 
        role="tabpanel" aria-labelledby="nav-messages-tab" tabindex="0">
          <!-- [class.low-opacity]="!order.accepted_by || allCompleted" -->
          <div class="row py-3">
            <h5 class="col-md-12 bg-secondary text-white rounded py-1">
              {{dictionary['3Dispozice'][country]}}
            </h5>
            
            <div class="col-lg-5">
              <app-itinerary-diary 
                [order]="order" 
                [token]="input_token"
                (completeItineraryShow)="completeItineraryShow($event)"
                (uploadFileEvent)="uploadFileEvent($event)"
                (closePreviewModal)="closePreviewModal($event)"
                (geolocationErrorEvent)="geolocationErrorEvent($event)"
                (geolocationStringEvent)="geolocationStringEvent($event)"
                (geolocationModalEvent)="openGeolocationModal($event)">
              </app-itinerary-diary>
            </div>

            <!-- <div class="col-md-3 mt-2">
              <button class="btn btn-outline-primary w-100" [disabled]="!validTokenOrder"
              (click)="openPreviewModal()">
                {{dictionary['NáhledDispozic'][country]}}
              </button>
            </div> -->
            <div class="col-lg-7">
              <div class="row">
                <div class="col-12">
                  <textarea class="form-control" [(ngModel)]="messageText" rows="6"></textarea>
                </div>
                <div class="col-lg-4 mt-2">
                  <button class="btn btn-outline-success w-100 px-1" 
                  (click)="sendWhatsAppMessage()" [disabled]="!validTokenOrder">
                    <img src="../../../assets/img/WhatsApp_logo.svg" height="22px" alt="whatsapp"/>
                    {{dictionary['ZprávaWhatsApp'][country]}}
                  </button>
                </div>
                <div class="col-lg-4 mt-2">
                  <button class="btn btn-outline-dark w-100 px-1" (click)="initSMS()" [disabled]="!validTokenOrder">
                    <img src="../../../assets/img/sms-solid.svg" height="22px" alt="sms"/>
                    {{dictionary['ZprávaSMS'][country]}}
                  </button>
                </div>
                <div class="col-lg-4 mt-2">
                  <button class="btn btn-outline-primary w-100 px-1" 
                  (click)="copyMessageToClipboard()" [disabled]="!validTokenOrder">
                    {{dictionary['KopírovatDispozice'][country]}}
                  </button>
                </div>
                <div class="col-12 mt-1">
                  <div class="fst-italic" [innerHtml]="'* ' + dictionary['Dispozice'][country]">
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-3 mt-2">
              <button class="btn btn-outline-primary w-100" [disabled]="!validTokenOrder"
              (click)="openPreviewModal()">
                {{dictionary['NáhledDispozic'][country]}}
              </button>
            </div>
            <div class="col-md-3 mt-2">
              <button class="btn btn-outline-success w-100" 
              (click)="sendWhatsAppMessage()" [disabled]="!validTokenOrder">
                <img src="../../../assets/img/WhatsApp_logo.svg" height="22px" alt="whatsapp"/>
                {{dictionary['ZprávaWhatsApp'][country]}}
              </button>
            </div>
            <div class="col-md-3 mt-2">
              <button class="btn btn-outline-dark w-100" (click)="initSMS()" [disabled]="!validTokenOrder">
                <img src="../../../assets/img/sms.svg" height="22px" alt="sms"/>
                {{dictionary['ZprávaSMS'][country]}}
              </button>
            </div>
            <div class="col-md-3 mt-2">
              <button class="btn btn-outline-primary w-100" 
              (click)="copyMessageToClipboard()" [disabled]="!validTokenOrder">
                {{dictionary['KopírovatDispozice'][country]}}
              </button>
            </div>
            <div class="col-sm-12 mt-1">
              <div class="fst-italic" [innerHtml]="'* ' + dictionary['Dispozice'][country]">
              </div>
            </div> -->
          </div>
        </div>
        <!-- 4. Dokončení nakládek a vykládek -->
        <div class="tab-pane fade" id="nav-itinerary" [class.show]="input_activate_nav == '4'" [class.active]="input_activate_nav == '4'"
        role="tabpanel" aria-labelledby="nav-itinerary-tab" tabindex="0">
          <!-- [class.low-opacity]="!order.accepted_by || allCompleted" -->
          <div class="row py-3">
            <h5 class="col-md-12 bg-secondary text-white rounded py-1">
              {{dictionary['4Dokončení'][country]}}
            </h5>
            
            <div class="col-12">
              <div class="mt-2 fw-bold">{{dictionary['Trasa'][country]}}:</div>
              <div *ngIf="order.itinerary?.length">
                <div *ngFor="let it of order.itinerary">
                  <div class="row mb-2">
                    <div class="col-md-9">
                      <div *ngIf="it.type == 'L' || it.type == 'U' || it.type == 'W' || it.type == 'V' || it.type=='T'">
                        <img *ngIf="it.type == 'L'" class="me-2" src="../../../assets/img/loading.svg" 
                        alt="icon" height="18px"/>
                        <img *ngIf="it.type == 'U'" class="me-2" src="../../../assets/img/unloading.svg" 
                        alt="icon" height="18px"/>
                        
                        <span *ngIf="order.delivery_type && (it.type=='W' || it.type=='V' || it.type=='T')">
                          <img *ngIf="order.delivery_type == 'D'" 
                          class="me-2" src="../../../assets/img/loading.svg" alt="icon" height="18px"/>
                          <img *ngIf="order.delivery_type == 'P'" 
                          class="me-2" src="../../../assets/img/unloading.svg" alt="icon" height="18px"/>
                        </span>

                        {{it.arrival_time_date | date: 'dd.MM.yyyy HH:mm'}} - 
                        
                        <span *ngIf="it.place_name && it.place_street && it.place_city && it.place_country; else fullAddress">
                          {{it.place_name}}, {{it.place_street}}, {{it.place_zip}} 
                          <b>{{it.place_city}}</b>, {{it.place_country}} 
                        </span>
                        <ng-template #fullAddress>{{it.address}}</ng-template>
                      </div>
                    </div>
                    <div class="col-md-3 text-center">
                      <ng-container *ngIf="!order.delivery_type">
                        <span *ngIf="it.completed" class="text-success">
                          {{dictionary['Dokončeno'][country]}}
                        </span>
                        <a *ngIf="!it.completed" class="btn {{itineraryButtonBackground}}" 
                        href="javascript:void(0)" 
                        (click)="itineraryToComplete = it; openCompleteItineraryModal()">
                          {{dictionary['PotvrditDokončení'][country]}}
                        </a>
                      </ng-container>

                      <ng-container *ngIf="order.delivery_type == 'P'">
                        <span *ngIf="order.warehouse_event_in" class="text-success">
                          {{dictionary['Dokončeno'][country]}}
                        </span>
                        <a *ngIf="!order.warehouse_event_in" class="btn {{itineraryButtonBackground}}" 
                        href="javascript:void(0)" 
                        (click)="itineraryToComplete = it; openCompleteItineraryModal()">
                          {{dictionary['PotvrditDokončení'][country]}}
                        </a>
                      </ng-container>

                      <ng-container *ngIf="order.delivery_type == 'D'">
                        <span *ngIf="order.warehouse_event_out" class="text-success">
                          {{dictionary['Dokončeno'][country]}}
                        </span>
                        <a *ngIf="!order.warehouse_event_out" class="btn {{itineraryButtonBackground}}" 
                        href="javascript:void(0)" 
                        (click)="itineraryToComplete = it; openCompleteItineraryModal()">
                          {{dictionary['PotvrditDokončení'][country]}}
                        </a>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 5. Elektronická fakturace -->
        <div class="tab-pane fade" id="nav-invoice" [class.show]="input_activate_nav == '5'" [class.active]="input_activate_nav == '5'"
        role="tabpanel" aria-labelledby="nav-invoice-tab" tabindex="0">
          <!-- [class.low-opacity]="!order.accepted_by && !allCompleted" -->
          <div *ngIf="!order.allow_einvoicing" class="row pt-4 pb-3">
            <h5 class="col-md-12 bg-secondary text-white rounded py-1">
              {{dictionary['5Fakturace'][country]}}
            </h5>
            <div class="col-md-12">
              <h5>
                <span class="text-danger">{{dictionary['Dokumentace-original'][country]}}</span>
              </h5>
            </div>
          </div>
          
          <div *ngIf="order.allow_einvoicing" class="row pt-4 pb-3">
            <!-- [class.low-opacity]="!order.accepted_by || !allCompleted" -->
            <h5 class="col-md-12 bg-secondary text-white rounded py-1">
              {{dictionary['5Fakturace'][country]}}
            </h5>
        
            <!-- [class.low-opacity]="!order.accepted_by || !allCompleted" -->
            <div class="col-md-12">
              <h5>
                <span class="text-danger">{{dictionary['Ušetřete'][country]}}</span>
                {{dictionary['Dokumentace'][country]}}
              </h5>
            </div>
        
            <!-- [class.low-opacity]="!order.accepted_by || !allCompleted" -->
            <div class="col-md-6 px-2">
              <div class="border border-secondary rounded">
                <div class="file-input border-bottom text-secondary">
                  <h5 class="text-white bg-secondary px-2 py-1">
                    {{dictionary['PřepravníDokumenty'][country]}}
                  </h5>
        
                  <input type="file" multiple (change)="droppedFile($event)" 
                  (click)="checkAcceptedOrder($event)"/>
          
                  <div class="mt-2 mx-auto text-center" style="width:90%;"
                  [innerHtml]="dictionary['Dokumentace-input-text1'][country]">
                  </div>
        
                  <div class="d-flex justify-content-center mx-auto mt-2" style="width: 90%;">
                    <div class="me-2" style="position: relative; z-index: 42;">
                      <a href="javascript:void(0)" 
                      (click)="openImgPattern('../../../assets/img/vzor-spatny1.jpg')">
                        <img src="../../../assets/img/vzor-spatny1.jpg" height="80px"/>
                      </a>
                    </div>
                    <div class="me-2" style="position: relative; z-index: 42;">
                      <a href="javascript:void(0)" 
                      (click)="openImgPattern('../../../assets/img/vzor-spatny2.jpg')">
                        <img src="../../../assets/img/vzor-spatny2.jpg" height="80px"/>
                      </a>
                    </div>
                    <div class="me-2" style="position: relative; z-index: 42;">
                      <a href="javascript:void(0)" 
                      (click)="openImgPattern('../../../assets/img/vzor-spatny3.jpg')">
                        <img src="../../../assets/img/vzor-spatny3.jpg" height="80px"/>
                      </a>
                    </div>
                    <div style="position: relative; z-index: 42;">
                      <a href="javascript:void(0)" 
                      (click)="openImgPattern('../../../assets/img/vzor-dobry.jpg')">
                        <img src="../../../assets/img/vzor-dobry.jpg" height="80px"/>
                      </a>
                    </div>
                  </div>
                  
                  <div class="mt-2 mx-auto text-center" style="width:90%;"
                  [innerHtml]="dictionary['Dokumentace-input-text2'][country]">
                  </div>
                  
                  <div class="w-100 my-3 text-center">
                    <img height="40px" src="../../../assets/img/folder_document.svg"/>
                  </div>
                </div>
        
                <div *ngIf="uploadingFile" class="text-center">
                  <div class="spinner-wrapper py-4">
                    <div class="spinner-border text-primary" style="width:4rem; height:4rem;" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </div>
        
                <div *ngIf="!uploadingFile && externalAttachments.length" class="py-2">
                  <div class="d-flex justify-content-start flex-wrap">
                    <div *ngFor="let f of externalAttachments" class="p-2">
                      <div *ngIf="f.thumbnail" class="thumbnail border rounded shadow" 
                      [ngbTooltip]="f.name" (click)="downloadFile(f)">
                        <img class="thumbnail" [src]="f.thumbnail.content | safeResource" alt="Náhled"/>
                      </div>
                      <div *ngIf="!f.thumbnail" class="thumbnail border rounded shadow"
                      [ngbTooltip]="f.name" (click)="downloadFile(f)">
                        <img class="thumbnail" src="../../../assets/img/folder_document.svg" alt="Náhled"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- [class.low-opacity]="!order.accepted_by || !allCompleted || !externalAttachments.length" -->
            <div class="col-md-6 px-2">
              <div *ngIf="!orderInvoiced" class="file-input text-secondary border border-secondary rounded">
                <h5 class="text-white bg-secondary px-2 py-1">
                  {{dictionary['FakturaZaPřepravu'][country]}}
                </h5>
                <div class="mt-2 mx-auto text-center" style="width:90%;"
                [innerHtml]="dictionary['Faktura-input'][country]">
                </div>
                <div class="w-100 mt-3 text-center">
                  <img height="40px" src="../../../assets/img/folder_document.svg"/>
                </div>
                <input id="pdfentryfile" type="file" (change)="droppedInvoiceFile($event)" 
                (click)="checkCompletingItinerary($event)"/>
        
                <div *ngIf="allCompleted" class="mt-2 mx-auto text-center" style="width:90%;">
                  {{dictionary['UživatelTA'][country]}}
                </div>
              </div>
        
              <div *ngIf="orderInvoiced" (click)="showInvoiceDetail()"
              class="file-input text-secondary border border-secondary rounded">
                <h5 class="text-white bg-secondary px-2 py-1">
                  {{dictionary['FakturaZaPřepravu'][country]}}
                </h5>
                <div class="mt-2 mx-auto text-center" style="width:90%;"
                [innerHTML]="dictionary['PřijatáFaktura'][country] | safeHtml">
                </div>
                <div class="mt-2 mx-auto text-center" style="width:90%;">
                  <img height="40px" src="../../../assets/img/check-green-transition.svg"/>
                </div>

                <!-- invoice details (same asi in invoiceModal) -->
                <div class="row px-2 pb-2">
                  <div class="col-lg-6 mb-1">
                    <div class="form-group">
                      <label>{{dictionary['VariabilniSymbol'][country]}}</label>
                      <input class="form-control form-control-sm" type="text" [ngModel]="invoiceVarSymbol" disabled/>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-1">
                    <div class="form-group">
                      <label>{{dictionary['CisloUctu'][country]}}</label>
                      <input class="form-control form-control-sm" type="text"
                      [ngModel]="invoiceAccount" maxlength="34" disabled/>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-1">
                    <div class="form-group">
                      <label>IBAN</label>
                      <input class="form-control form-control-sm" type="text" [ngModel]="invoiceIban" disabled/>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-1">
                    <div class="form-group">
                      <label>Swift</label>
                      <input class="form-control form-control-sm" type="text" maxlength="12"
                      [ngModel]="invoiceSwift" maxlength="12" disabled/>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-1">
                    <div class="form-group">
                      <label *ngIf="order">
                        {{dictionary['ČástkaBezDph'][country]}} {{order.contracted_price}} {{order.currency}}
                      </label>
                      <div class="input-group input-group-sm">
                        <input type="number" class="form-control rounded" [ngModel]="invoicePrice" disabled/>
                        <div class="input-group-append w-25">
                          <input class="form-control form-control-sm" type="text" [ngModel]="invoiceCurrency" disabled/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-1">
                    <div class="form-group">
                      <label>{{dictionary['ČástkaSDph'][country]}} *</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control rounded" type="number" [ngModel]="invoicePriceTotal" disabled/>
                        <div class="input-group-append w-25">
                          <input class="form-control form-control-sm" type="text" [ngModel]="invoiceCurrency" disabled/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-1">
                    <div class="form-group">
                      <label>Sazba DPH *</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control form-control-sm rounded" type="number" [ngModel]="invoiceVatRate" disabled/>
                        <div class="input-group-append w-25">
                          <input class="form-control form-control-sm" type="text" value="%" disabled/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-1">
                    <div class="form-group">
                      <label>{{dictionary['DPH'][country]}} *</label>
                      <div class="input-group input-group-sm">
                        <input class="form-control form-control-sm rounded" type="number" [ngModel]="invoiceVatHome" disabled/>
                        <div class="input-group-append w-25">
                          <input class="form-control form-control-sm" type="text" [ngModel]="our_currency" disabled/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-1">
                    <div class="form-group">
                      <label>{{dictionary['Plnění'][country]}}</label>
                      <input class="form-control form-control-sm" type="date"  
                      [ngModel]="invoiceFullfilment" [disabled]="orderInvoiced"/>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-1">
                    <div class="form-group">
                      <label>{{dictionary['Splatnost'][country]}} *</label>
                      <input class="form-control form-control-sm" type="date" [ngModel]="invoiceMaturity" disabled/>
                    </div>
                  </div>
                  <!-- <div class="col-md-4 mb-1">
                    <label>Kurz</label>
                    <input class="form-control form-control-sm" type="number" min="0"
                    [(ngModel)]="invoiceExchangeRate" disabled/>
                  </div> -->
                  <!-- <div class="col-md-12">
                    <div class="form-group">
                      <label>{{dictionary['Poznámka'][country]}}</label>
                      <textarea class="form-control form-control-sm" [placeholder]="dictionary['Doplnte'][country]" 
                      [(ngModel)]="invoiceNote" [disabled]="orderInvoiced">
                      </textarea>
                    </div>
                  </div> -->
                  <!-- <div *ngIf="invoiceFile" class="col-md-12 mt-3">
                    <h6>{{dictionary['SouborFaktury'][country]}}</h6>
                    <div class="text-primary">{{invoiceFile.name}}</div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading && !accessForbidden && dictionary" class="container mt-1 mb-4 text-center">
  <h6 [innerHTML]="dictionary['TruckAgenda'][country] | safeHtml">
  </h6>
</div>


<div class="modal fade" id="confirmationModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header">
        <!-- <h5 *ngIf="spz && accepted_by" class="modal-title">
          {{dictionary['PotvrditObjednávku'][country]}}
        </h5>
        <h5 *ngIf="!spz || !accepted_by" class="modal-title text-danger">
          {{dictionary['OpravtePovinnéPole'][country]}}
        </h5> -->
        <h5 *ngIf="accepted_by" class="modal-title">
          {{dictionary['PotvrditObjednávku'][country]}}
        </h5>
        <h5 *ngIf="!accepted_by" class="modal-title text-danger">
          {{dictionary['OpravtePovinnéPole'][country]}}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- <h6 *ngIf="spz && accepted_by">{{dictionary['Ověření'][country]}}</h6>
        <h6 *ngIf="!spz || !accepted_by" class="text-danger">* {{dictionary['OpravtePovinnéPole'][country]}}</h6> -->
        <h6 *ngIf="accepted_by">
          {{dictionary['Ověření'][country]}}
        </h6>
        <h6 *ngIf="!accepted_by" class="text-danger">
          * {{dictionary['OpravtePovinnéPole'][country]}}
        </h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zrušit'][country]}}
        </button>
        <button type="button" class="btn btn-success" (click)="updateOrder()" 
        data-bs-dismiss="modal" [disabled]="!accepted_by">
        <!-- [disabled]="!spz || !accepted_by"> -->
          {{dictionary['Potvrdit'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="confirmationEditModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{dictionary['UpravitUdaje'][country]}}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6>{{dictionary['OvěřeníUpravy'][country]}}</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zrušit'][country]}}
        </button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
        (click)="additionalUpdateOrder()">
          {{dictionary['UpravitUdaje'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="previewModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{dictionary['NáhledDispozic'][country]}}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-itinerary-diary 
          [order]="order" 
          [token]="input_token"
          (completeItineraryShow)="completeItineraryShow($event)"
          (uploadFileEvent)="uploadFileEvent($event)"
          (closePreviewModal)="closePreviewModal($event)"
          (geolocationErrorEvent)="geolocationErrorEvent($event)"
          (geolocationStringEvent)="geolocationStringEvent($event)"
          (geolocationModalEvent)="openGeolocationModal($event)">
        </app-itinerary-diary>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zavřít'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="geolocationModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header shadow {{geolocationError ? 'bg-danger' : 'bg-warning'}}">
        <h5 class="modal-title">Vaše poloha</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6 [innerHTML]="geolocationString | safeHtml"></h6>

        <h6 *ngIf="geolocationError" class="mt-3">
          * Doporučený prohlížeč pro využívání všech funkcí je Google Chrome.
        </h6>
        <h6 *ngIf="geolocationError" class="mt-2">
          * V iOS využijte AppStore pro stažení prohlížeče 
          <a href="https://apps.apple.com/us/app/apple-store/id535886823">Google Chrome</a>.
        </h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Zavřít
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="completeItineraryModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{dictionary['Dokončení'][country]}}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-itinerary-confirmation 
          [itinerary]="itineraryToComplete" 
          [token]="input_token"
          [order]="order"
          (completed)="completed($event)">
        </app-itinerary-confirmation>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zavřít'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="importModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{dictionary['ImportovatTA'][country]}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6>{{dictionary['OpravduImportovat'][country]}}</h6>
        <h6>{{dictionary['ImportPřesměrovat'][country]}}</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zrušit'][country]}}
        </button>
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal" 
        (click)="importToTruckAgenda()">
          {{dictionary['Importovat'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="imgModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{dictionary['Vzor'][country]}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="imgPatternSrc" class="text-center">
          <img [src]="imgPatternSrc" alt="vzor"/>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zavřít'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="pdfModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-fullscreen-lg-down">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="order?.order_number_standard" class="modal-title">
          {{dictionary['Objednávka'][country]}} {{order.order_number_standard}}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="orderPdf?.content" class="text-center w-100" style="min-height: 65vh;">
          <object class="order-pdf" style="min-height: 65vh;"
          [data]="orderPdf.content | safeResource" [type]="orderPdf.type">
          </object>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="orderPdf?.content" type="button" class="btn btn-warning" 
        (click)="downloadOrderPdf()">
          {{dictionary['Stáhnout'][country]}} {{orderPdfName}}
        </button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zavřít'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="smsModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{dictionary['ZprávaSMS'][country]}}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6>{{dictionary['Tel.Řidiče'][country]}}: *</h6>
        <input class="form-control" [class.border-danger]="smsPhoneInvalid" [(ngModel)]="smsPhone"
        placeholder="+420123456789"/>
        <div *ngIf="smsPhoneInvalid" class="fst-italic text-danger">
          * Formát +420123456789
        </div>

        <h6 class="mt-3">{{dictionary['TextZpravy'][country]}}:</h6>
        <div class="fst-italic">{{smsText}}</div>

        <!-- google recaptcha -->
        <div class="form-row mt-4">
          <div class="col">
            <label class="mb-1 font-weight-bold">{{dictionary['Recaptcha'][country]}}</label>
            <recaptcha id="smsRecaptcha" [formControl]="smsRecaptcha"></recaptcha>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zrušit'][country]}}
        </button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="sendSMS()"
        [disabled]="!captchaChecked || smsPhoneInvalid">
          {{dictionary['OdeslatSMS'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="alertModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header bg-danger shadow">
        <h5 class="modal-title">{{dictionary['Upozornění'][country]}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6>{{dictionary['NelzeAkce'][country]}}</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zavřít'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="invoiceAlertModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header bg-danger shadow">
        <h5 class="modal-title">{{dictionary['Upozornění'][country]}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6>
          {{dictionary['NelzeFaktura1'][country]}}
        </h6>
        <h6>
          {{dictionary['NelzeFaktura2'][country]}}
        </h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zavřít'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="invoiceModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="order?.order_number_standard" class="modal-title">
          {{dictionary['PřidatFakturu'][country]}} OB{{order.order_number_standard}}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6 *ngIf="QR_missing" class="fw-bold fst-italic text-danger mb-3">
          * {{dictionary['FakturaQRkod'][country]}}
        </h6>

        <div class="row">
          <div [class]="'col-xl-' + (orderInvoiced ? '12' : '6')">
            <div class="row">
              <div class="col-md-7 mb-1">
                <div class="form-group">
                  <label>{{dictionary['VariabilniSymbol'][country]}} *</label>
                  <input class="form-control {{invoiceVarSymbol ? 'border-success' : 'border-danger'}}" 
                  type="text" [placeholder]="dictionary['Doplnte'][country]" 
                  [(ngModel)]="invoiceVarSymbol" [disabled]="orderInvoiced"/>
                </div>
              </div>
              <!-- <div class="col-md-6 mb-1">
                <div class="form-group mb-1">
                  <label>{{dictionary['KonstantniSymbol'][country]}}</label>
                  <select class="form-select" [(ngModel)]="invoiceConstantSymbol" 
                  [disabled]="orderInvoiced">
                    <option [ngValue]="null">Zvolte symbol</option>
                    <option value="0308">0308 / Platby za dodávky prací, výkonu a služeb</option>
                    <option value="0008">0008 / Platby za zboží</option>
                    <option value="0038">0038 / Prostředky na mzdy</option>
                    <option value="0138">0138 / Srážky z mezd</option>
                    <option value="0168">0168 / Splátky úvěrů a půjček</option>
                    <option value="0558">0558 / Finanční platby ostatní</option>
                    <option value="0938">0938 / Dávky sociálního zabezpečení</option>
                    <option value="1148">1148 / Běžné platby daní, poplatků a odvodů cel</option>
                    <option value="3558">3558 / Platby pojistného pojišťovnám</option>
                    <option value="9818">9818 / Mimořádné odvody v průběhu roku</option>
                  </select>
                </div>
              </div> -->
              <div class="col-md-5 mb-1">
                <div class="form-group">
                  <label>{{dictionary['CisloUctu'][country]}}</label>
                  <input class="form-control" type="text" [placeholder]="dictionary['Doplnte'][country]" 
                  [(ngModel)]="invoiceAccount" [disabled]="orderInvoiced" maxlength="34"/>
                </div>
              </div>
              <div class="col-md-7 mb-1">
                <div class="form-group">
                  <label>IBAN * 
                    <a class="badge bg-warning text-secondary ml-1" href="javascript:void(0)"
                    [ngbPopover]="popoverIban" container="body" placement="right">?</a>
                    <ng-template #popoverIban>
                      <div>{{dictionary['IBANinfo'][country]}}</div>
                    </ng-template>
                  </label>
                  <input class="form-control {{invoiceIban && invoiceIban.length < 42 ? 'border-success' : 'border-danger'}}" 
                  type="text" [placeholder]="dictionary['Doplnte'][country]" (keyup)="ibanFormatting()"
                  [(ngModel)]="invoiceIban" [disabled]="orderInvoiced"/>
                </div>
              </div>
              <div class="col-md-5 mb-1">
                <div class="form-group">
                  <label>Swift</label>
                  <input class="form-control" 
                  type="text" maxlength="12" [placeholder]="dictionary['Doplnte'][country]"
                  [(ngModel)]="invoiceSwift" [disabled]="orderInvoiced" maxlength="12"/>
                </div>
              </div>
              <div class="col-md-7 mb-1">
                <div class="form-group">
                  <label *ngIf="order">
                    {{dictionary['ČástkaBezDph'][country]}} {{order.contracted_price}} {{order.currency}}
                  </label>
                  <div class="input-group">
                    <input type="number" class="form-control rounded" 
                    [(ngModel)]="invoicePrice" [disabled]="orderInvoiced"
                    (ngModelChange)="changeInvoicePriceVat()"/>
                    <div class="input-group-append">
                      <select class="form-select" [class.border-danger]="!invoiceCurrency"
                      style="max-width: 80px;" [(ngModel)]="invoiceCurrency" [disabled]="orderInvoiced">
                          <option value="CZK">CZK</option>
                          <option value="EUR">EUR</option>
                          <option value="GBP">GBP</option>
                          <option value="USD">USD</option>
                          <option value="CHF">CHF</option>
                          <option value="DKK">DKK</option>
                          <option value="PLN">PLN</option>
                          <option value="NOK">NOK</option>
                          <option value="HUF">HUF</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div *ngIf="QR_diff_price" class="text-danger fst-italic">* {{dictionary['HodnotyQR'][country]}}</div>
              </div>
              <div class="col-md-5 mb-1">
                <div class="form-group">
                  <label>{{dictionary['ČástkaSDph'][country]}} *</label>
                  <div class="input-group">
                    <input class="form-control rounded {{invoicePriceTotal ? 'border-success' : 'border-danger'}}"
                    type="number" [(ngModel)]="invoicePriceTotal" [disabled]="orderInvoiced"
                    (ngModelChange)="changeInvoicePriceTotal()"/>
                    <div class="input-group-append">
                      <select class="form-select" [class.border-danger]="!invoiceCurrency" 
                      style="max-width: 80px;" [(ngModel)]="invoiceCurrency" [disabled]="orderInvoiced">
                        <option value="CZK">CZK</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                        <option value="USD">USD</option>
                        <option value="CHF">CHF</option>
                        <option value="DKK">DKK</option>
                        <option value="PLN">PLN</option>
                        <option value="NOK">NOK</option>
                        <option value="HUF">HUF</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div *ngIf="QR_diff_price_total" class="text-danger fst-italic">* {{dictionary['HodnotyQR'][country]}}</div>
              </div>
              <div class="col-md-7 mb-1">
                <div class="form-group">
                  <!-- <label>{{dictionary['DPH'][country]}} *</label> -->
                  <label>Sazba DPH *</label>
                  <div class="input-group">
                    <input class="form-control rounded {{invoiceVatRate || invoiceVatRate == 0 ? 'border-success' : 'border-danger'}}"
                    type="number" [(ngModel)]="invoiceVatRate" [disabled]="orderInvoiced"
                    (ngModelChange)="changeInvoicePriceVat()"/>
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5 mb-1">
                <div class="form-group">
                  <label>{{dictionary['DPH'][country]}} *</label>
                  <div class="input-group">
                    <input class="form-control rounded {{invoiceVatHome || invoiceVatHome == 0 ? 'border-success' : 'border-danger'}}"
                    type="number" [(ngModel)]="invoiceVatHome" [disabled]="orderInvoiced"
                    (ngModelChange)="changeInvoiceVatHome()"/>
                    <div class="input-group-append">
                      <span class="input-group-text">{{our_currency}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5 mb-1">
                <div class="form-group">
                  <label>{{dictionary['Plnění'][country]}}</label>
                  <input class="form-control" type="date"  
                  [(ngModel)]="invoiceFullfilment" [disabled]="orderInvoiced"
                  (change)="exchangeRateUpdate()"/>
                </div>
              </div>
              <div class="col-md-3 mb-1">
                <label>Kurz</label>
                <div class="input-group">
                  <input class="form-control rounded" type="number" min="0"
                  [(ngModel)]="invoiceExchangeRate" disabled/>
                </div>
              </div>
              <!-- <div class="col-md-4 mb-1">
                <div class="form-group mb-1">
                  <label class="text-nowrap">{{dictionary['ZpůsobÚhrady'][country]}} *</label>
                  <select class="form-select {{invoicePaymentMethod ? 'border-success' : 'border-danger'}}" 
                  [(ngModel)]="invoicePaymentMethod" [disabled]="orderInvoiced">
                    <option value="T">{{dictionary['Převodem'][country]}}</option>
                    <option value="C">{{dictionary['Hotově'][country]}}</option>
                    <option value="D">{{dictionary['Dobírkou'][country]}}</option>
                    <option value="P">{{dictionary['Zálohou'][country]}}</option>
                  </select>
                </div>
              </div> -->
              <div class="col-md-4 mb-1">
                <div class="form-group">
                  <label>{{dictionary['Splatnost'][country]}} *</label>
                  <input class="form-control {{invoiceMaturity ? 'border-success' : 'border-danger'}}" 
                  type="date" [(ngModel)]="invoiceMaturity" [disabled]="orderInvoiced || orderMaturityDefined"/>
                </div>
                <div *ngIf="QR_diff_maturity" class="text-danger fst-italic">* {{dictionary['HodnotyQR'][country]}}</div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>{{dictionary['Poznámka'][country]}}</label>
                  <textarea class="form-control" [placeholder]="dictionary['Doplnte'][country]" 
                  [(ngModel)]="invoiceDescription" [disabled]="orderInvoiced">
                  </textarea>
                </div>
              </div>
              
              <div class="col-md-12 fst-italic">
                * {{dictionary['PovinnéÚdaje'][country]}}
              </div>
    
              <div *ngIf="invoiceFile" class="col-md-12 mt-3">
                <h6>{{dictionary['SouborFaktury'][country]}}</h6>
                <div class="text-primary">{{invoiceFile.name}}</div>
              </div>
            </div>
          </div>
          <div *ngIf="!orderInvoiced && currentPdf" class="col-xl-6">
            <div style="height:65vh;">
              <object class="full fill" [data]="currentPdf.content|safeResource" [type]="currentPdf.type">
              </object>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zavřít'][country]}}
        </button>
        <button *ngIf="!orderInvoiced" type="button" class="btn btn-success" (click)="createInvoice()"
        [disabled]="emptyRequiredInvoiceParams" data-bs-dismiss="modal">
          {{dictionary['UložitFakturu'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="alertSuccessModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div *ngIf="dictionary" class="modal-content">
      <div class="modal-header bg-success shadow">
        <h5 class="modal-title">{{dictionary['Dokončena'][country]}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6>
          {{dictionary['Hotovo'][country]}}<br>
          {{dictionary['Děkujeme'][country]}}
        </h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          {{dictionary['Zavřít'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>