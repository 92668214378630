import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WarehouseEvent } from '../model/warehouse-event.object';
import { HttpClient } from '@angular/common/http';
import { serviceConfiguration } from '../config';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(
    private _http: HttpClient
  ) { }

  // method for getting all warehouse events of selected car in specified time interval
  // NOT USING ATTRIBUTES BUT CUSTOM INSTANCES
  // tf - time_from, tt - time_to, ck - car_key, dk - driver_key, on - order_number, ok - order_key
  // GET /events/warehouse/
  getEvents(token: string | null, filterObj: any): Observable<any> {
    let url: string = serviceConfiguration.warehouseEvent.api;
    url += '?token=' + token;

    // add url query
    if (filterObj) {
      let filterKeys: Array<string> = Object.keys(filterObj);
      filterKeys.forEach(
        key => {
          if (filterObj[key]) {
            url += '&' + key + '=' + filterObj[key];
          }
        }
      );
    }

    return this._http.get(url);
  }

  // method for creating warehouse event objects from api objects
  static buildEventsFromData(data: Array<any>): any {
    let events: Array<WarehouseEvent> = [];
    data.forEach(
      i => {
        let event = WarehouseService.buildEvent(i);
        events.push(event);
      }
    );
    return events;
  }

  // method for creating a single warehouse event object
  static buildEvent(i: any): WarehouseEvent {
    let event: WarehouseEvent = new WarehouseEvent();
    for (let key in i) {
      // @ts-ignore
      event[key] = i[key];
    }

    return event;
  }
}
