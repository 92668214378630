export const apiHost: string = 'https://' + '//app2.truckmanager.eu';
let api = apiHost + '/nettedev/api/v1';
// let api = 'http://localhost:8080/TruckManagerAPI';

export const serviceConfiguration = {
  orders : {
    api               : api + '/external/orders/?token=<TOKEN>&order=<ORDER>',
    apiAttachmentGet  : api + '/external/orders/attachment/<FILE>?token=<TOKEN>&order=<ORDER>&obligation=<OBLIGATION>',
    apiAttachmentPost : api + '/external/orders/attachment/?token=<TOKEN>&order=<ORDER>&obligation=<OBLIGATION>',
    apiInvoicePost    : api + '/external/orders/invoice/?token=<TOKEN>&order=<ORDER>&obligation=<OBLIGATION>',
    apiCompany        : api + '/external/orders/company/?token=<TOKEN>&order=<ORDER>',
    apiTinTA          : api + '/external/orders/company/?token=<TOKEN>&order=<ORDER>&tin=<TIN>',
    apiSettingsDocs   : api + '/external/orders/settings/docs/?token=<TOKEN>&order=<ORDER>',
    apiInvoicing      : api + '/external/orders/settings/invoicing/?token=<TOKEN>&order=<ORDER>',
    apiLogo           : api + '/external/orders/settings/logo/?token=<TOKEN>&order=<ORDER>',
    apiPdf            : api + '/external/orders/pdf?token=<TOKEN>&order=<ORDER>',
    dictionary        : './assets/orders.json'
  },
  obligations : {
    apiGetAll           : api + '/external/obligations/?token=<TOKEN>',
    apiGet              : api + '/external/obligations/<OBLIGATION_KEY>?token=<TOKEN>',
    apiCreate           : api + '/external/obligations/?token=<TOKEN>',
    apiCreateItinerary  : api + '/external/obligations/<OBLIGATION_KEY>/itinerary/?token=<TOKEN>',
    apiEmail            : api + '/external/obligations/<OBLIGATION_KEY>/email?token=<TOKEN>&lang=<LANG>',
    apiThumbnail        : api + '/external/obligations/attachment/<FILE>?token=<TOKEN>&obligation=<OBLIGATION>&size=thumb',
    apiAttachmentGet    : api + '/external/obligations/attachment/<FILE>?token=<TOKEN>&obligation=<OBLIGATION>',
    apiAttachmentPost   : api + '/external/obligations/attachment/?token=<TOKEN>&obligation=<OBLIGATION>',
    apiCompany          : api + '/external/obligations/company/?token=<TOKEN>',
    apiBookItem         : api + '/external/obligations/book?token=<TOKEN>',
    apiSettings         : api + '/external/obligations/settings/docs/?token=<TOKEN>',
    apiLogo             : api + '/external/obligations/settings/logo/?token=<TOKEN>',
    dictionary          : './assets/obligations.json'
  },
  itinerary : {
    apiComplete     : api + '/obligations/complete-itinerary?token=<TOKEN>&oblig_key=<OBLIGATION_KEY>&itin_key=<ITINERARY_KEY>'
  },
  warehouseEvent : {
    api             : api + '/events/warehouse/' 
  },
  files : {
    apiToPdf        : api + '/files/topdf?token=<TOKEN>',
    apiThumbnail    : api + '<PATH>?token=<TOKEN>',
    apiThumbnail2   : api + '/<PATH>?token=<TOKEN>&size=thumb'
  },
  sms : {
    api             : api + '/sms/'
  },
  exchangeRates: {
    api             : api + '/exchange-rates'
  },
  internal : {
    bug_report      : api + '/internal/bug-report'
  },
  diary : {
    dictionary      : './assets/diary.json'
  }
};