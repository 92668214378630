<div *ngIf="itinerary" class="container-fluid bg-secondary-dark py-3 h-100" 
  style="min-height: 600px; max-width: 450px;">
  <div *ngIf="uploading" class="container text-center">
    <div class="spinner-wrapper py-4">
      <div class="spinner-border text-warning" style="width:3rem; height:3rem;" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>

  <div *ngIf="!uploading" class="container">
    <div class="bg-warning rounded shadow px-2 py-1 mb-3">
      <h4 class="text-center m-0">
        <span>
          {{itinerary.type == 'L' ? 
          dictionary['Nakládka'][country] : 
          dictionary['Vykládka'][country]}}
        </span> - {{dictionary['DokončeníLabel'][country]}}
      </h4>
      <h6 class="text-center my-1">{{itinerary.address}}</h6>
    </div>

    <div class="row">
      <div class="col-12 mb-3">
        <div class="form-group row">
          <label class="col-6 text-white">
            {{dictionary['Zakázka'][country]}}
          </label>
          <div class="col-6">
            <input *ngIf="itinerary.obligation" id="order_number_input" 
            class="form-control text-white bg-secondary-dark" 
            type="text" placeholder="Číslo zakázky" disabled
            [ngModel]="itinerary.obligation.order_number_standard"/>
          </div>
        </div>
      </div>
      <div class="col-12 mb-2 pt-3 border-top">
        <div class="form-group row">
          <label class="col-6 text-white">{{dictionary['DatumČas'][country]}}</label>
          <div class="col-6">
            <input class="form-control text-white bg-secondary-dark" 
            type="datetime-local" [(ngModel)]="it_arrival_time"/>
          </div>
        </div>
      </div>
      <div class="col-12 mb-1">
        <div class="form-group row">
          <label class="col-6 text-white">{{dictionary['Hmotnost'][country]}} (t)</label>
          <div class="col-6">
            <input class="form-control text-white bg-secondary-dark" 
            type="number" placeholder="0" min="0" max="100" [(ngModel)]="it_weight"
            (change)="weightValidate()"/>
          </div>
        </div>
      </div>
      <div class="col-12 mb-3">
        <div class="form-group row">
          <label class="col-6 text-white">{{dictionary['LožnáDélka'][country]}} (m)</label>
          <div class="col-6">
            <input class="form-control text-white bg-secondary-dark" 
            type="number" placeholder="0" min="0" max="100" [(ngModel)]="it_length"
            (change)="lengthValidate()"/>
          </div>
        </div>
      </div>
      
      <h5 class="col-12 text-white text-center pt-2 border-top">
        {{dictionary['Obaly'][country]}}
      </h5>

      <div class="col-12 mb-2">
        <div class="form-group row">
          <label class="col-6 text-white">{{dictionary['Odevzdal'][country]}}</label>
          <div class="col-6">
            <input class="form-control text-white bg-secondary-dark" 
            type="number" placeholder="0" [(ngModel)]="it_containers_out"/>
          </div>
        </div>
      </div>
      <div class="col-12 pb-3">
        <div class="form-group row">
          <label class="col-6 text-white">{{dictionary['Naložil'][country]}}</label>
          <div class="col-6">
            <input class="form-control text-white bg-secondary-dark" 
            type="number" placeholder="0" [(ngModel)]="it_containers_in"/>
          </div>
        </div>
      </div>

      <div class="col-12 pt-2 border-top">
        <div class="form-group row">
          <h5 class="col-12 text-white text-center">{{dictionary['Poznámka2'][country]}}</h5>
          <div class="col-12">
            <textarea class="form-control text-white bg-secondary-dark" 
            placeholder="Text poznámky až 100 znaků..." style="height: 80px"
            [(ngModel)]="it_descr" (keydown)="descrValidate()"></textarea>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row my-4">
      <div class="col-12 mb-3">
        <button class="btn btn-primary w-100" type="button" (click)="completeItinerary()">
          {{dictionary['PotvrditDokončení'][country]}}
        </button>
      </div>
      <div class="col-6 mb-2">
        <!-- <a class="btn btn-warning w-100" [href]="'geo:' + itinerary.gps_coord | safeResource">
          Naviguj
        </a> -->
        <a *ngIf="itinerary.gps_coord_normalized" class="btn btn-warning w-100" type="button"
        [href]="'geo:' + itinerary.gps_coord_normalized | safeResource" target="_blank">
          {{dictionary['Naviguj'][country]}}
        </a>
      </div>
      <div class="col-6">
        <button class="btn btn-secondary w-100" type="button" disabled>
          {{dictionary['Dokumenty'][country]}}
        </button>
      </div>
    </div>
  </div>
</div>